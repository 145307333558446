<template>
  <div>
    <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="当前项目" class="default-highlight">
            <a-input :disabled="true" :placeholder="designCode"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合同编号">
            <a-input style="width: 100%" v-decorator="[
                            'contractCode',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]">
              <ContractSelector slot="addonAfter" @change="onSelectContract">
                <a-button type="primary"> 获取合同 </a-button>
              </ContractSelector>
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="工程名称" class="default-highlight" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
            <a-input :disabled="true" :placeholder="projectName" />
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="实施控制预算(万元)">
            <a-input-number :formatter="$inputNumberFormat" style="width: 100%" v-decorator="[
                            'budget',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]"></a-input-number>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合同价(万元)">
            <a-input-number :formatter="$inputNumberFormat" style="width: 100%" v-decorator="[
                            'contractPrice',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]"></a-input-number>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="主要内容" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
            <a-textarea :auto-size="{ minRows: 5 }" v-decorator="[
                            'content',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]"
              placeholder="实施控制预算组成：编制说明、实施控制预算汇总表、实施控制预算明细表（建筑工程、安装工程、设备材料、其他费用、现场管理费）、实施控制预算与投标控制预算对比分析表、目标利润测算汇总表等。" />
          </a-form-item>
        </a-col>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
            <span slot="label" class="">附件</span>
            <div class="link-list" v-decorator="[
                            'file',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请上传附件！',
                                },
                              ],
                            },
                          ]">
              <a-checkbox-group :value="selectedFileList" @change="onSelectFile" v-if="fileList.length !== 0">
                <div class="link" v-for="item in fileList" :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank" download :href="item.completePath">{{
                                          item.name
                                          }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
              <div style="color: #ccc" v-else-if="fileList.length === 0 && id">
                无
              </div>
              <div style="color: #ccc" v-else>《实施控制预算》</div>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control" @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit" type="primary" v-if="!isView">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/plan/budgetReview";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";
import ContractSelector from "@/components/contract-selector";

export default {
  components: {
    FileUpload,
    ContractSelector,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_implementation_budget_review", //实施控制预算审核
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],

      hid: "",
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, isView, hid } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.isView = Boolean(isView) || false;
    this.hid = hid || "";

    if (this.id) {
      this.getDetail();
    }
    this.getName();
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.id = res.id;
          this.form.setFieldsValue({
            budget: res.budget,
            contractCode: res.contractCode,
            contractPrice: res.contractPrice,
            content: res.content,
            file: res.attachmentList,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    exportForm() {
      exportFile({ id: this.id })
        .then((blob) => {
          saveAs(blob, `${this.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate()
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },

    onSelectContract(value) {
      console.log(value.contractAmount);
      this.form.setFieldsValue({
        contractCode: value.code,
      });
      this.form.setFieldsValue({
        contractPrice: value.contractAmount * 0.0001,
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;
          if (!this.id) {
            add({
              ...values,
              pid: this.pid,
              type: this.stage,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
            }).then(() => {
              this.form.resetFields();
              this.fileList = [];
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              type: this.stage,
              historyId: this.hid,
              id: this.id,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>
