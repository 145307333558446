var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.designCode}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'contractCode',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'contractCode',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}],staticStyle:{"width":"100%"}},[_c('ContractSelector',{attrs:{"slot":"addonAfter"},on:{"change":_vm.onSelectContract},slot:"addonAfter"},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" 获取合同 ")])],1)],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.projectName}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"实施控制预算(万元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'budget',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'budget',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同价(万元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'contractPrice',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'contractPrice',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"主要内容","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'content',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'content',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"auto-size":{ minRows: 5 },"placeholder":"实施控制预算组成：编制说明、实施控制预算汇总表、实施控制预算明细表（建筑工程、安装工程、设备材料、其他费用、现场管理费）、实施控制预算与投标控制预算对比分析表、目标利润测算汇总表等。"}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'file',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请上传附件！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'file',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请上传附件！',\n                              },\n                            ],\n                          },\n                        ]"}],staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v("《实施控制预算》")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }