import request from '@/api/request'
import download from '@/api/download'

export function add(data) {
  return request({
      url: '/market-service/epc/planning/implementationBudgetReview/save',
      method: 'post',
      data
  })
}

export function edit(data) {
  return request({
      url: '/market-service/epc/planning/implementationBudgetReview/modify',
      method: 'post',
      data,
  })
}

export function fetchDetail(params) {
  return request({
      url: `/market-service/epc/planning/query/${params.id}`,
  })
}

// 下载模版
export function downloadTemplate() {
  return download({
      url: '/market-service/epc/planning/implementationBudgetReview/export/template',
  })
}
// 导出
export function exportFile(params) {
  return download({
      url: `/market-service/epc/planning/implementationBudgetReview/export/${params.id}`,
  })
}

